import React from 'react';
import { graphql } from 'gatsby';
import SeoComponent from '../components/SeoComponent';
import LayoutComponent from '../components/LayoutComponent';

const Basic = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  return (
    <LayoutComponent bodyClass="page-default-single">
      <div className="container pb-6 pt-2 pt-md-2 pb-md-10">
        <div className="row justify-content-start">
          <div className="">
            <h1 className="title">{title}</h1>
            <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
      }
      fields {
        slug
      }
      html
    }
  }
`;

export default Basic;
